import { template as template_6ac643b8d1864f2fa7ea499c05be0c87 } from "@ember/template-compiler";
const FKLabel = template_6ac643b8d1864f2fa7ea499c05be0c87(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
