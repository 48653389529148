import { template as template_d912530ff6524885a4b65271c7b68b5d } from "@ember/template-compiler";
const WelcomeHeader = template_d912530ff6524885a4b65271c7b68b5d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
